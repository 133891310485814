import React, { Component } from "react";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="section-title text-center">
              <h2>על גו-קוד</h2>
            </div>
            <div className="col-xs-12 col-md-2 profile">
              {" "}
              <img src="img/profile.jpg" className="img-responsive" alt="" />
              <p>
                אלעד כהן<br/>
                יזם גו-קוד ומרצה בכיר
              </p>
            </div>
            <div className="col-xs-12 col-md-10">
              <p>
                {this.props.data ? this.props.data.paragraph : "loading..."}
              </p>
              <div className="list-style about-text">
                <h3>למה לבחור בנו?</h3>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {this.props.data
                      ? this.props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {this.props.data
                      ? this.props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
