import React from "react";
import Navigation from "../components/navigation";
import Header from "../components/header";
import Features from "../components/features";
import About from "../components/about";
// import Services from "../components/services";
// import Gallery from "../components/gallery";
import Testimonials from "../components/testimonials";
import Price from "../components/price";
import Contact from "../components/contact-pay";

import JsonData from "../data/data.json";

import queryString from "query-string";

import "./style.css";
import SEO from "../components/seo";
// // styles
// const pageStyles = {
//   color: "#232129",
//   padding: "96px",
//   fontFamily: "-apple-system, Roboto, sans-serif, serif",
// };
// const headingStyles = {
//   marginTop: 0,
//   marginBottom: 64,
//   maxWidth: 320,
// };
// const headingAccentStyles = {
//   color: "#663399",
// };
// const paragraphStyles = {
//   marginBottom: 48,
// };
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// };
// const listStyles = {
//   marginBottom: 96,
//   paddingLeft: 0,
// };
// const listItemStyles = {
//   fontWeight: "300",
//   fontSize: "24px",
//   maxWidth: "560px",
// };

// const linkStyle = {
//   color: "#8954A8",
//   fontWeight: "bold",
//   fontSize: "16px",
//   verticalAlign: "5%",
// };

// const docLinkStyle = {
//   ...linkStyle,
//   listStyleType: "none",
//   marginBottom: 24,
// };

// const descriptionStyle = {
//   color: "#232129",
//   fontSize: "14px",
// };

// const docLink = {
//   text: "Documentation",
//   url: "https://www.gatsbyjs.com/docs/",
//   color: "#8954A8",
// };
// data
//
// const links = [
//   {
//     text: "Tutorial",
//     url: "https://www.gatsbyjs.com/docs/tutorial/",
//     description:
//       "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
//     color: "#E95800",
//   },
//   {
//     text: "How to Guides",
//     url: "https://www.gatsbyjs.com/docs/how-to/",
//     description:
//       "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
//     color: "#1099A8",
//   },
//   {
//     text: "Reference Guides",
//     url: "https://www.gatsbyjs.com/docs/reference/",
//     description:
//       "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
//     color: "#BC027F",
//   },
//   {
//     text: "Conceptual Guides",
//     url: "https://www.gatsbyjs.com/docs/conceptual/",
//     description:
//       "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
//     color: "#0D96F2",
//   },
//   {
//     text: "Plugin Library",
//     url: "https://www.gatsbyjs.com/plugins",
//     description:
//       "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
//     color: "#000000",
//   },
// ];
// markup
// import "animate.css/animate.min.css";

const IndexPage = ({ location }) => {
  const landingPageData = JsonData;
  const { offer, af } = queryString.parse(location.search);

  const offerData = {
    offer: {
      ...landingPageData.Price["normal"],
      ...landingPageData.Price[offer ? offer : "normal"],
    },
    normal: landingPageData.Price["normal"],
  };

  return (
    <div>
      <SEO data={offerData} />
      <Navigation />
      <Header data={landingPageData.Header} />
      <Contact data={landingPageData.Contact} affiliate={af} offer={offer} />
      <Features data={landingPageData.Features} offer={offer} />
      <About data={landingPageData.About} />
      {/* <Services data={landingPageData.Services} /> */}
      {/* <Gallery /> */}
      <Testimonials data={landingPageData.Testimonials} />
      <Price affiliate={af} data={offerData} />
      <Contact
        data={landingPageData.Contact}
        affiliate={af}
        offer={offer}
        full
      />
    </div>
  );
};

export default IndexPage;
