import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "./accordion.css";
// import "animate.css/animate.min.css";
// import ScrollAnimation from "react-animate-on-scroll";
function Price({ data, affiliate }) {
  const specialOffer = data.offer.price !== data.normal.price;
  const dtOffer = affiliate === "dt";
  return (
    <div id="price" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          {/* <ScrollAnimation animateIn="flipInY" animateOut="flipOutY"> */}
          <h2>מחיר וזמני הקורס</h2>

          {specialOffer ? (
            <div className="row">
              מחיר רגיל: <span>{data.normal.price} ש״ח</span>
              <br />
              <span style={{ fontWeight: "bold" }}>
                מחיר מיוחד עבור תלמידי ובוגרי {data.offer.name}:{" "}
                {data.offer.price} ש״ח בלבד.
                {data.offer.hasifa && (
                  <>
                    <br />
                    <br />
                    {/* ההטבה למשלמים במועד ערב החשיפה שיערך ב 15.3.21 בשעה 20:00
                <br />
                <br /> */}
                    הצטרפו לקבוצת הווצאפ - בקבוצה יישלח הקישור לערב הפתוח,
                    ובנוסף ניתן לשאול בקבוצה כל שאלה בהקשר לקורס ⬇️
                    <br />
                    <br />
                    <a
                      href={data.offer.hasifa}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="btn btn-custom btn-lg">
                        לקבוצת הוואטסאפ!
                      </button>
                    </a>
                    <br />
                    <br />
                  </>
                )}
              </span>
            </div>
          ) : dtOffer ? (
            <div className="row">
              מחיר רגיל: <span>{data.normal.price} ש״ח</span>
              <br />
              <span style={{ fontWeight: "bold", fontSize: 28 }}>
                מחיר לחברי דיגיטק: 2590 ש"ח בלבד!
                <br />
                <br />
                🎁 בנוסף תינתן הטבה נוספת למשלמים במועד ערב החשיפה,
                <br />
                בו נדבר על איך תהפכו למפתחי Fullstack תוך חודשיים.
                <br />
                <br />
                ערב החשיפה יערך ב24.3 בשעה 20:00
                <br />
                <br />
                לנרשמים כאן ישלח קישור לערב החשיפה
              </span>
            </div>
          ) : (
            <div className="row">
              התמורה הטובה ביותר, במחיר משתלם: {data.normal.price} ש״ח בלבד!
            </div>
          )}

          <div className="row">
            התשלום כולל תרגולים מלאים, מצגות, הקלטות של כל השיעורים, סיוע בקבוצת
            וואטסאפ ייעודית לאורך כל הקורס תוך מענה לכל שאלה, עזרה בהשמה וטיפים
            מהתעשייה לקבלה מהירה לעבודה
          </div>
          <div className="row">
            <table
              className="table hours-table"
              style={{ width: "inherit", margin: "0 auto" }}
            >
              <thead>
                <tr>
                  <th scope="col">תאריך</th>
                  <th scope="col">שעות</th>
                </tr>
              </thead>
              <tbody>
                {data.offer.time.map((el) => (
                  <React.Fragment key={el.day}>
                    <tr>
                      <th scope="row">{el.day}</th>
                      <td>{el.hours}</td>
                    </tr>
                    <tr
                      style={{
                        direction: "ltr",
                        textAlign: "left",
                      }}
                    >
                      <td
                        style={{
                          color: "#5ca9fb",
                        }}
                        colSpan={2}
                      >
                        {el.syl}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row text-right">
            <Accordion allowZeroExpanded="true">
              {data.offer.qas.map((qa) => (
                <AccordionItem key={qa.q}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{qa.q}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>{qa.a}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          {/* </ScrollAnimation> */}
        </div>
        {/* <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div> */}
      </div>
    </div>
  );
}

export default Price;
