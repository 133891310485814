import React from "react";

const Features = ({ data, offer }) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="row section-title">
          <h2>אז מה כולל הקורס?</h2>
        </div>
        <div className="row video">
          <div className="col-md-offset-2 col-md-8 col-xs-12">
            <div className="row video-caption">צפו בשיעור לדוגמא</div>
            <div className="row iframe-container">
              <div className="col-md-offset-1 col-md-10">
                <iframe
                  title="yt-lesson"
                  src="https://www.youtube.com/embed/wB2c2EI3fPQ"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {data
            ? data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="tech col-xs-6 col-md-3">
                  {" "}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
        {/* <div className="row">
          <a
            href={offer ? `/pdf/syllabus-${offer}.pdf` : `/pdf/syllabus.pdf`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-custom btn-lg page-scroll"
          >
            להורדת הסילבוס המלא
          </a>{" "}
        </div> */}
      </div>
    </div>
  );
};

export default Features;
