import React from "react";

function Testimonials({ data }) {
  return (
    <div id="testimonials" className="text-center">
      <div className="container">
        <div className="row section-title">
          <h2>מה אומרים עלינו?</h2>
        </div>
        {/* <div className="row video">
          <div className="col-md-offset-2 col-md-8 col-xs-12">
            <div className="row video-caption">צפו בחוות דעת מצולמת</div>
            <div className="row iframe-container">
              <div className="col-md-offset-1 col-md-10">
                <iframe
                  title="yt-testimonial"
                  src="https://www.youtube.com/embed/z63DZZ6IZUk"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div> */}
        <div
          id="myCarousel"
          className="row carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {data
              ? data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className={`col-12 item ${i === 0 ? "active" : ""}`}
                  >
                    <div className="testimonial">
                      <div className="testimonial-image text-center">
                        {" "}
                        <img src={d.img} alt="" />{" "}
                      </div>
                      <div className="testimonial-content">
                        <p>{d.text}</p>
                        <div className="testimonial-meta"> - {d.name} </div>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <a
            className="left carousel-control"
            href="#myCarousel"
            data-slide="prev"
          >
            <span className="fa fa-chevron-right"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control"
            href="#myCarousel"
            data-slide="next"
          >
            <span className="fa fa-chevron-left"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
