import React from "react";

function Header({ data }) {
  return (
    <header id="header">
      <div className="intro">
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="cube"></div>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <img src="img/logo.png" height="157" alt="" className="logo" />
                <img
                  src="img/digitech.png"
                  height="157"
                  alt=""
                  className="logo-digitech"
                />
                <h1>
                  {data ? data.title : "Loading"}
                  <span></span>
                </h1>
                <p className="paragraph">{data ? data.paragraph : "Loading"}</p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  רוצה עוד פרטים!
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wa-button">
        <a
          href="https://api.whatsapp.com/send?phone=972525710099&text=אהלן. אשמח לקבל פרטים על קורס הפולסטאק שלכם"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>
    </header>
  );
}

export default Header;
