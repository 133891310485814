import React from "react";
const JsonData = require("../data/data.json");

function Contact({ offer, data }) {
  console.log("offer", offer);
  const offerData = {
    offer: {
      ...JsonData.Price["normal"],
      ...JsonData.Price[offer ? offer : "normal"],
    },
    normal: JsonData.Price["normal"],
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-12">
            <div className="row">
              <div className="section-title">
                <h2>קדימה להרשמה!</h2>
                <p>זה הזמן לטוס למעלה עם גו-קוד</p>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                {offerData && (
                  <button
                    type="submit"
                    className="btn btn-custom btn-lg"
                    style={{ width: "70%" }}
                    onClick={() => {
                      window.location = offerData.offer.payment;
                    }}
                  >
                    אני בפנים!
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {data ? data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {data ? data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {data ? data.email : "loading"}
                </p>
              </div>
            </div> */}
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={data ? data.facebook : "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={data ? data.telegram : "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={data ? data.youtube : "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
