import React from "react";


const Navigation = () => {

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#header">
            קורס פולסטאק של גו-קוד
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-left">
            <li
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <a
                href="#features"
                className="page-scroll"
              >
                על הקורס
              </a>
            </li>
            <li
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <a href="#about" className="page-scroll">
                עלינו
              </a>
            </li>
            {/* <li>
                <a href="#services" className="page-scroll">
                  Services
                </a>
              </li>
              <li>
                <a href="#portfolio" className="page-scroll">
                  Gallery
                </a>
              </li> */}
            <li
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <a href="#testimonials" className="page-scroll">
                חוות דעת
              </a>
            </li>
            <li
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <a href="#price" className="page-scroll">
                מחיר
              </a>
            </li>
            <li
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <a href="#contact" className="page-scroll">
                להרשמה!
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
